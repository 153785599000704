@import 'bootstrap';
@import 'lightbox';


@font-face {
  font-family: 'aller';
  src: url('/fonts/aller.woff2') format('woff2'),
    url('/fonts/aller.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'oswaldregular';
  src: url('/fonts/oswald-regular-webfont.woff2') format('woff2'),
    url('/fonts/oswald-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family:"dashiell";
  src:url("https://use.typekit.net/af/921890/00000000000000007735f0da/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/921890/00000000000000007735f0da/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/921890/00000000000000007735f0da/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
  }
.container-no {
  padding:0 50px;
  @media only screen and (max-width: 768px ) {
    padding:0 20px;
  }
}
.medium-font {
  font-family: 'dashiell';
  -webkit-font-smoothing: antialiased;
}

.light-font {
  font-family: 'aller';
}

.regular-font {
  font-family: 'aller';
}


.mt-new {
  margin-top: 30px;
}

.mb-new {
  margin-bottom: 30px;
}

$bronze: #d7ad90;
// layout

.container-fluid {
  @media (max-width: #{map-get($grid-breakpoints, sm)}) {
    padding-left: 10px;
    padding-right: 10px;

  }
  @media (max-width: #{map-get($grid-breakpoints, md)}) {
    padding-left: 20px;
    padding-right: 20px;

  }
  padding-left: 30px;
  padding-right: 30px;
}
.container-lg-fluid {
  @media (min-width: #{map-get($grid-breakpoints, lg)}) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

$nav-bg: rgba(12, 65, 96, .8);

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: $spacer / 2 0;
  text-align: right;
  z-index: 1000;
  background: $nav-bg;

  nav {
    position: fixed;
    background: $taupe;
    top: 0;
    right: 0;
    width: 0; // displays when .nav-visible class added
    max-width: 100%;
    height: 0; // displays when .nav-visible class added
    z-index: 200;
    text-align: left;
    text-transform: uppercase;
    font-size: $font-size-lg;
    padding-top: 80px;
    transition: all .6s;
    transition-timing-function: ease-out;

    ul {
      margin: 0 40px;
      list-style: none;
      padding-left: 30%;
      @media (min-width: #{map-get($grid-breakpoints, lg)}) {
        padding-left: 50%;
      }

      &.menu-break {
        margin-top: 0;
      }

      li {
        font-size: 116%;
        margin-bottom: 15px;
        opacity: 0;
        transform: translate(-40px);
        transition: all .8s;

        &.item-visible {
          opacity: 1;
          transform: translate(0);
        }
      }
    }

    &.nav-visible {
      overflow-y: auto;
      width: 100%;
      height: 100vh;
    }
  }

  @media (max-width: #{map-get($grid-breakpoints, sm)}) {
    nav {
      width: 0; // animates to 100%
      max-width: 100%;

      ul {
        padding-left: 0%;

        li {
          font-size: 120%;
        }
      }
    }
  }
 
  .btn {
    box-shadow: none!important;
    margin-top: 8px;
    position: relative;
    z-index: 300;
    &:focus{
      box-shadow: none!important;
    }
    @media (max-width: #{map-get($grid-breakpoints, sm)}) {
      padding-left: 2vw!important;
      padding-right: 2vw!important;
    }
    
  }

  .logo {
    width: 40px;
    float: left;
    position: relative;

    &.logo-menu {
      z-index: 300;
      display: none;
    }
  }
}

footer {
  background-color: #0c4160;

  .footer-logo {
    height: 60px;
    @media (min-width: #{map-get($grid-breakpoints, md)}) {
        height: 100px;
    }
  }

  .fa {
    margin: 0 $spacer / 4;
  }

  .kbsa-logo {
    width: 100%;
    max-width: 100px;
  }


  .iq-logo {
    width: 100%;
    max-width: 60px;
    margin-bottom: $spacer * 2;
  }

  .social-wrap {
    span {
      color: $white;
      font-size: $font-size-sm;
      margin-top: $spacer * 2;
      float: left;

      /*            @media (min-width: #{map-get($grid-breakpoints, md)}) {
                float: left;
                margin-top: $spacer * 2;
            }*/
    }
  }
}

.background-gray {
  background-color: $taupe;
}

.background-taupe {
  background-color: $taupe;
}
.background-blueNew {
  background-color: $blueNew;
}
.background-gold {
  background-color: $gold;
}

.background-white {
  background-color: $white;
}
.opacity-05{
  opacity: .5;
}

.card-block{
  @media (min-width: #{map-get($grid-breakpoints, xxl)}) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  img{
    object-fit: cover;
  }
}
.card-no-border {
  border-width: 0;
}

.text-xl-lg {
  @media (min-width: #{map-get($grid-breakpoints, xl)}) {
    font-size: $font-size-lg;
  }
}
.text-lg {
  font-size: $font-size-lg!important;
}
.text-md {
  font-size: $font-size-md!important;

  @media (min-width: #{map-get($grid-breakpoints, ld)}) {
    font-size: $font-size-sm!important;
  }

  @media (min-width: #{map-get($grid-breakpoints, xxl)}) {
    font-size: $font-size-md!important;
  }
}
.text-sm {
  @media (max-width: #{map-get($grid-breakpoints, xl)}) {

    font-size: $font-size-sm!important;
  }
}
.text-xs {
  font-size: $font-size-xs!important;
}

@media (max-width: #{map-get($grid-breakpoints, sm)}) {
  .text-center-sm {
    text-align: center !important;
  }

  .text-right-sm {
    text-align: right !important;
  }

  .text-left-sm {
    text-align: left !important;
  }
}

@media (max-width: #{map-get($grid-breakpoints, lg)}) {
  .text-center-md {
    text-align: center !important;
  }

  .text-right-md {
    text-align: right !important;
  }

  .text-left-md {
    text-align: left !important;
  }
}

.fa {
  line-height: 1.1rem;
}

.fa-spin {
  animation: fa-spin 1s infinite linear;
}

.btn {
  white-space: normal;
  font-family: 'dashiell', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: $font-size-md;
  max-width: 100%;
  @media (min-width: #{map-get($grid-breakpoints, lg)}) {
    &:hover {
      cursor: pointer;
    }
  }
}

.clickable {
  text-decoration: underline;
  @media (min-width: #{map-get($grid-breakpoints, lg)}) {

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
  }
}

$trans-white: rgba(255, 255, 255, .6);
$trans-black: rgba(0, 0, 0, .5);
$trans-blue: rgba(12, 65, 96, .8);
$sales-red: $red;
.btn-secondary{
  border: none!important;
  @media (min-width: #{map-get($grid-breakpoints, lg)}) {

    &:hover{
      color: $blueNew !important;
      background:$white!important;
    }
  }
}
.btn-outline-secondary, .btn-secondary {

  @media (min-width: #{map-get($grid-breakpoints, lg)}) {
    &.active {
      background: transparent!important;
      color: white!important;
    }
    &:hover {
      background: white!important;
      color: #0c4160!important;
    }
  }

  &.red {
    border-color: $sales-red;
    @media (min-width: #{map-get($grid-breakpoints, lg)}) {

      &:hover {
        background-color: $sales-red;
        color: $white;
      }
      
    }
      &:focus {
      box-shadow: none;
    }
  }
}

.btn-like-heading {
  color: $white;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: $font-size-sm;
  display: inline-block;
  font-weight: $btn-font-weight;
  line-height: $btn-line-height;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid $trans-white;
  padding: $btn-padding-y $btn-padding-x;
  border-radius: 0;
  transition: all .2s ease-in-out;
}

hr {

  display: inline-block;
  width: $spacer * 1.5;
  margin-top: $spacer / 2;
  margin-bottom: $spacer / 2;

  &.hr-white {
    border-color: $white;
  }

  &.hr-dark {
    border-color: $darkBlueNew;
  }

  &.hr-black {
    border-color: rgb(29, 28, 28);
  }

  &.hr-lg {
    width: $spacer * 4;
  }
}
.hamburger-wrapper{
  height:$font-size-md;
  .hamburger-line{
    height: 1px;
    background: currentColor;
    width: 2rem!important;
  }
}

card {

  hr {
    border-color: darken($taupe, 20%);
  }
}

.hr-title {
  height: 1.2rem;
  overflow: visible;
  border-width: 0;
  border-style: solid;
  border-color: $hr-border-color;
  border-bottom-width: ceil($hr-border-width / 2);
  background-color: $white;
  text-align: center;

  .hr-title-content {
    display: inline-block;
    background-color: inherit;
    padding-left: $spacer;
    padding-right: $spacer;
  }
}
html {
  -webkit-font-smoothing: antialiased;
}
@media (max-width: #{map-get($grid-breakpoints, xs)}) {
  html {
    font-size: 3vw;
  }
}
@media (max-width: #{map-get($grid-breakpoints, sm)}) {
  html {
    font-size: 14px;
  }
}

$i: 1;

@while $i < 7 {

  h#{$i},
  .h#{$i} {
    line-height: 1.4;
  }

  $i: $i+1;
}

h1,
.h1 {
  letter-spacing: 2px;
}

h2,
.h2,
h3,
.h3 {
  letter-spacing: 1px;
}

$n: 1;

@while $n < 5 {
  .display-#{$n} {
    font-family: $headings-font-family;
    display: block;
  }

  $n: $n+1;
}

.display-1 {
  letter-spacing: 4px;
}

.display-2 {
  letter-spacing: 3px;
}

.display-3,
.display-4 {
  letter-spacing: 2px;
}

.img-background {
  background-position: center;
  background-size: cover;
  height: 400px;
  @media (min-width: #{map-get($grid-breakpoints, sm)}) {
    height: 350px!important;
  }
  @media (min-width: #{map-get($grid-breakpoints, lg)}) {
    height: 400px!important;
  }
  @media (min-width: #{map-get($grid-breakpoints, xl)}) {
    height: 500px!important;
  }

  &.tall {
    @media (max-width: #{map-get($grid-breakpoints, sm)}) {
      min-height: 450px!important;
    }
    @media (min-width: #{map-get($grid-breakpoints, md)}) {
      min-height: 600px!important;
    }
  }

  &.tall-image {
    @media (max-width: #{map-get($grid-breakpoints, sm)}) {
      min-height: 550px!important;
    }
    @media (min-width: #{map-get($grid-breakpoints, md)}) {
      min-height: 45vw!important;
    }
  }
  
  &.img-background-square {
    width: 100%;
    padding-top: 100%;
    min-height: 0;
    height: auto!important;
    transition: .4s ease;
    @media (min-width: #{map-get($grid-breakpoints, lg)}) {
      &:hover{
        transform: scale(1.03)
      }
    }
  }


  &.img-background-cover {
    height: 250px;
  }
  &.img-tinted {
    background-color: $trans-black;
    background-blend-mode: multiply;
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(grayscale=1)";
  }

  &.full-height {
    min-height: 350px;
    height: 100%;
  }

  &.extra-height {
    min-height: 700px;
    height: 100%;
    @media (max-width: #{map-get($grid-breakpoints, md)}) {
      min-height: 400px;
    }
  }

  &.auto-height {
    height: auto;
  }

  &.clickable {
    @media (min-width: #{map-get($grid-breakpoints, lg)}) {

      &:hover {
        opacity: .9;
      }
    }
  }
}
.overflow-auto{
  overflow:visible
}

.page-item {
  @media (min-width: #{map-get($grid-breakpoints, lg)}) {

    &.active {
      .page-link {
        color: $white !important;
      }
    }
    &:hover{
      a{
        color:white!important
      }
    }
  }
}

.overflow-hidden{
  overflow: hidden;
}
.text-white {
  a {
    color: $white;
    @media (min-width: #{map-get($grid-breakpoints, lg)}) {

     
    }
  }
}

.slide-in-wrapper {
  overflow: hidden;
  display: flex;
  min-width: 100%;
  min-height: 100%;

  &>div {
    min-width: 100%;
    min-height: 100%;
  }

  $translate-offset: 40px;

  .below-the-fold {
    transition: all 1s;

    &.slide-in-left {
      transform: translate(-$translate-offset);
    }

    &.slide-in-right {
      transform: translate($translate-offset);
    }
  }

  .above-the-fold {
    transition: all 1s;
  }

}

.form-control {
  font-family: $font-family-base;
  font-weight: 200;
  &:focus{
    border: 1px solid $gray-dark;
  }
}

$social-icons: 'facebook',
'twitter',
'instagram',
'youtube',
'houzz';
$social-icon-size: 30px;
$social-icon-size-lg: 40px;

@mixin social-icons($icons, $icon-size) {
  @each $icon in $icons {
    $i: index($icons, $icon) - 1;

    &.social-icon-#{$icon} {
      background-position: 0 - ($icon-size * $i) 0;
    }
  }
}

.social-icon {
  display: inline-block;
  width: $social-icon-size;
  height: $social-icon-size;
  background: url("/assets/images/ki-social-sprite.png") no-repeat 0 0;
  background-size: $social-icon-size * 5 $social-icon-size;
  @media (min-width: #{map-get($grid-breakpoints, lg)}) {

    &:hover {
      opacity: .8;
    }
  }
    
  &.social-icon-grey {
    background-image: url("/assets/images/ki-social-sprite-blue.svg");
  }

  .video-steaming{
    object-fit: cover;
  }

  @include social-icons($social-icons, $social-icon-size);

  &.social-icon-lg {
    width: $social-icon-size-lg;
    height: $social-icon-size-lg;
    background-size: $social-icon-size-lg * 5 $social-icon-size-lg;

    @include social-icons($social-icons, $social-icon-size-lg);
  }

}

.houzz-logo {
  width: 120px;
}

.card-img-top {
  width: 100%;
}
.card-img-top {
  &.sales{

    height: 100%;
  }
}

.hidden {
  opacity: 0;
  transition: opacity 1s;
}

.visible {
  opacity: 1;
  transition: opacity 1s;
}

// components

.contact-form-inner {

  .close {
    opacity: 1!important;
    transition: .4s ease;
    color: $blueNew;
    display: block;
    @media (min-width: #{map-get($grid-breakpoints, lg)}) {
      &:hover{
        transform: rotate(90deg);
      }
    }
    -webkit-focus-ring-color: transparent;
    outline-color:transparent;
  }
}
.dashiell{
  font-family:"dashiell";
}
.body {
  font-size: 1.1rem;
}

.contact-form {
  z-index: 110000;

  .form-control {
    text-align: center;
    color: $blueNew!important;
  }
  ::placeholder{
    color: $gray-dark!important;

  }
  textarea {
    min-height: 120px;
  }

  @media (max-width: #{map-get($grid-breakpoints, sm)}) {

    .modal-body {
      padding: 0;
    }
  }

  .contact-form-inner {

    .close {
      display: inline-block;
    }
  }
}

.opt-in-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it
  z-index: 100;
  width: 20px;
  height: 20px;
  border: 1px solid $blueNew;
  cursor: pointer;

  &+label {
    position: relative;
    padding: 0;
    cursor: pointer;
    z-index: 1;
    width: 20px;
    height: 20px;
  }
  
  // Box.
  &+label:before {
    content: '';
    border: 1px solid $blueNew;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
  }

  // Box hover
  @media (min-width: #{map-get($grid-breakpoints, lg)}) {
    &:hover+label:before {
      /*background: #f35429;*/
    }
  }
    
  // Box focus
 
  // Box checked
  &:checked+label:before {
    /*background: #f35429;*/
    background: #ffffff;
  }

  // Disabled state label.
  &:disabled+label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled+label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked+label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 12px;
    background: #666;
    width: 2px;
    height: 2px;
    box-shadow:
      2px 0 0 #666,
      4px 0 0 #666,
      4px -2px 0 #666,
      4px -4px 0 #666,
      4px -6px 0 #666,
      4px -8px 0 #666;
    transform: rotate(45deg);
  }
}

.color-darkBlue{
  color: $darkBlueNew;
}
.color-blueNew{
  color: $blueNew;
}
.color-gold{
  color: $gold;
}

.store-locations {

  .store-title {
    min-height: 52px;
    @media (min-width: #{map-get($grid-breakpoints, lg)}) {

      &:hover {
        cursor: pointer;
        opacity: .8;
      }
    }
  }

  @media (max-width: #{map-get($grid-breakpoints, sm)}) {

    .store-title {
      min-height: 20px;
    }
  }

.pointer-events-none{
  pointer-events: none;
}
.pointer-events-auto{
  pointer-events: auto;
}

.position-absolute{
  position: absolute!important
}

  .map-container {
    position: relative;
    .map-overlay{
      pointer-events: none;
      background: rgba(12, 65, 96, 0.2);
    }
  }
  
  .map {
    min-height: 300px;
  }

  .opening-hours {
    pointer-events: none;
    position: absolute;
    top: 40px;
    right: 60px;
    @media (min-width: #{map-get($grid-breakpoints, xl)}) {
      right: 100px;
    }

    table {

      td {
        padding: 0 10px;
      }
    }
  }

  .address {
    position: absolute;
    top: 40px;
    left: 40px;
    @media (min-width: #{map-get($grid-breakpoints, xl)}) {
      left: 80px;
    }
  }
}
.position-absolute{
  position: absolute
}

@media (max-width: #{map-get($grid-breakpoints, sm)}) {

  .store-locations {

    h4 {
      min-height: 0;
    }

    .map-container {

      .address {
        left: 10%;
        width: 80%;
      }
    }
  }
}

.img-fluid {
  min-width: 100%;
  height: auto;

  &.full-height {
    max-width: none;
    height: 100%;
  }
}

.after-scroll-heading {
  width: 100%;
  margin-left: -$grid-gutter-width-base / 2;
  margin-top: $spacer * 1.5;
  position: absolute;
  display: block;
  text-align: center;
}

// pages

$intro-bottom-hugger: rgba($black, .4);

.introduction {
  background-image: url("/assets/images/ki-home-0000.jpg"); // default - overriden in templates
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  min-height: 400px;
  height: 90vh;
  position: relative;
}
.ch2-btn-primary{
  background: $blueNew!important;
  @media (min-width: #{map-get($grid-breakpoints, lg)}) {

    &:hover{
      background: $blueNew!important;
    }
  }
}
.ch2-icon{
  z-index: 2!important;
  right: 20px!important;
}

.heading {
  // background-color: $intro-bottom-hugger;
  width: 100%;
  height: 100%;
  text-align: center;
  color: $white;
  background: rgba(0, 0, 0, .3);

  h1 {
    font-size: 3rem;
  }

  hr {
    border-color: $white;
  }
}

p{
  margin-bottom: 1.5rem;
}
.sub-heading {
  text-align: center;

  p {
    margin-bottom: 0;
  }
}

.home {

  .introduction {
    height: 700px;
    height: 100vh;
    position: relative;

    .call-to-action-area {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      padding-top: 35vh;

      .fade-in-images {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30%;
        height: 30vh;
        

        h1 {
          width: 100%;
          font-size: 4rem;
        }

        img {
          width: 80%;
          max-width: 520px;
        }

        h1,
        img {
          display: none;
        }
      }

     
    }
  }

  .carousel-item {
    &.active {
      display: block;
    }
  }

  .carousel-item-next,
  .carousel-item-prev {
    display: block;
  }
  .cards-home{
    .card-img-top {
      height: 20rem;
      @media (min-width: #{map-get($grid-breakpoints, xl)}) {
        height: 35rem;
      }
    }
  }
  .carousel-home {
    .card-img-top {
      min-width: auto;
      width: 100%;
    }
  }

}

.luxury-sales{
  overflow: hidden;
  .introduction {
    height: 700px;
    height: 100vh;
    position: relative;

    .call-to-action-area {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      padding-top: 25%;
      padding-top: 25vh;

      .fade-in-images {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30%;
        height: 30vh;
        

        h1 {
          width: 100%;
          font-size: 4rem;
        }

        img {
          width: 80%;
          max-width: 520px;
        }

        h1,
        img {
          display: none;
        }
      }

     
    }
  }
}

.design-service {

  .background-image {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 100%;

    hr {
      margin-bottom: 75%;
    }
  }
}

.stories,
.events {

  .introduction {
    background-image: url("/assets/images/ki-news-0000.jpg");
    height: 300px;
  }

  .heading {
    padding-top: 15vh;
  }
  select{
    border: 1px solid $blueNew!important;
  }
  .blog-control {
    padding-top: $spacer * 2;
    padding-bottom: $spacer;

    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;

      li {
        display: inline-block;
        text-transform: uppercase;
        margin-right: $spacer * 2;
        @media (min-width: #{map-get($grid-breakpoints, lg)}) {

          &:hover {
            cursor: pointer;
            opacity: .8;
          }
        }
      }
    }
  }

  .img-container {
    max-height: 345px;
    overflow: hidden;
  }

  .article-snippet {

    img {
      max-width: 100%;
      height: auto;
      margin: $spacer auto;
    }

    iframe {
      max-width: 100%;
      display: block;
      margin: $spacer auto;
    }
  }

}

.align-items-center-md{
  @media (max-width: #{map-get($grid-breakpoints, lg)}) {
    align-items: center;
  }
}

.story,
.event {



  .heading {
    padding-top: 18%;
    padding-top: 18vh;
  }

  article {

    img {
      max-width: 100%;
      height: auto;
      margin: $spacer auto;
    }
  }
}

.store {

  .introduction {
    background-image: url("/assets/images/ki-about-0000.jpg");
    height: 300px;
  }

  .heading {
    padding-top: 18%;
    padding-top: 18vh;
  }
}

.contact {

  .introduction {
    height: 300px;
  }

  .heading {
    padding-top: 18%;
    padding-top: 18vh;
  }
}

.endorsements {

  @media (max-width: #{map-get($grid-breakpoints, sm)}) {
    .col {
      flex-basis: initial;
    }
  }

  p {
    img {
      max-width: 100%;
      max-height: 300px;
    }
  }

  .our-clients {
    .col {
      &:nth-child(2) {
        background-color: $taupe;
      }

      .flex-column {
        height: 100%;

        p {
          &:last-child {
            margin-top: auto;
          }
        }
      }
    }
  }
}
.appliance-span{
  span{
    color: $gold;
  }
}
.luxury-overlay{
  position:absolute;
  width:100%;
  height:100%;
  top:0;
  left:0;
  z-index: 2;
}
.dark-overlay{
  position:absolute;
  width:100%;
  height:100%;
  top:0;
  left:0;
  z-index: 2;
  background: rgba(0,0,0,.2)
}
.on-top{
  z-index: 1000;
}
.z-2{
  z-index: 2;
}
.z-3{
  z-index: 3;
}

.font-dashiell{
  font-family: 'dashiell';
  -webkit-font-smoothing: antialiased;
}
.font-aller{
  font-family: 'aller';
}

.red-span {
    span {
        color: $sales-red;
    }
}
// .sales {
//     h1, h2 {font-weight: 700;}
//     .aller, .font-oswald p {
//         font-family: "oswaldextralight", Helvetica, Arial, sans-serif;
//         color: #3e3d3d;
//         font-weight: 600;
//     }

//     .lts {
//         letter-spacing: 1.2px;
//     }

//     .carousel-item {
//         &.active {
//             display: block;
//         }
//     }

//     .carousel-item-next,
//     .carousel-item-prev {
//         display: block;
//     }

//     .carousel-sales {
//         .card-img-top {
//             min-width: auto;
//             width: 100%;
//             //min-height: 450px;

//             //@media (max-width: #{map-get($grid-breakpoints, sm)}) {
//                 //min-height: auto;
//             //}
//         }
//     }

//     .heading {
//         display: none;
//     }

//     .end-text {
//         line-height: 3rem;
//     }
//     .sub-heading-bckg {
//         background: $sales-red;
//     }
//     .background-taupe.dark-inset {
//         background: $black;
//         opacity: .6;
//         position: relative;
//         top: 43%;
//         left: 0;
//         bottom: 0;
//         width: 100%;
//     }

//     .sub-heading {
//         p {
//             font-size: 36px;
//             font-weight: bold;
//             font-family: $headings-font-family;
//             color: $white;
//         }
//     }

//     .img-background {
//         &.full-height {
//             min-height: 600px;
//         }
//     }

//     @media (max-width: #{map-get($grid-breakpoints, sm)}) {
//         .col {
//             flex-basis: initial;
//         }
//     }

//     p {
//         img {
//             max-width: 100%;
//             max-height: 300px;
//         }
//     }
// }
.carousel-sales {
  height: 400px;
  overflow: hidden;

  @media screen and (min-width: 768px){
    height: 1000px;
  }

  @media screen and (min-width: 1300px){
    height: 1000px;
  }

}

.card-text{
  font-size: 1.2rem;
  @media screen and (min-width: 1600px){

    font-size: 1.4rem;
  }
  @media screen and (min-width: 1920px){

    font-size: 1.7rem;
  }
}

.luxury-carousel-sales {
  height: 65vw;
  @media screen and (min-width: 768px){

    height: 65vw;
  }
  @media screen and (min-width: 1200px){

    height: 800px;
  }
  overflow: hidden;

}
.ex-display-sales {
  .appliance-pack {
    img {
      max-width: 100%;
      width: 100%;
    }
  }

  .heading {
    border-top: 10px solid rgba(255, 0, 0, 1);
    ;
    background-color: $sales-red;

    h1 {
      font-weight: bold;
      font-size: 3.2rem;
    }
  }

  .red-bottom {
    -webkit-box-shadow: inset 0px -5px 0px 0px rgba(255, 0, 0, 1);
    -moz-box-shadow: inset 0px -5px 0px 0px rgba(255, 0, 0, 1);
    box-shadow: inset 0px -5px 0px 0px rgba(255, 0, 0, 1);
  }

  .articles {
    .sale-price {
      color: $sales-red;
    }
  }

  .footer-text {
    .h2 {
      font-size: 1.6rem
    }

    p {
      text-transform: none;
    }
  }
}

.ex-display-sales {
  .red-span {
    span {
      color: $sales-red;
    }
  }

  .title {
    font-size: 1.6rem;

    span {
      color: $sales-red;
    }
  }

  p {
    font-size: 1rem;
  }
}

.ex-display-sales .background-taupe.dark-inset {
  background: #000;
  opacity: .6;
  position: relative;
  top: 43%;
  left: 0;
  bottom: 0;
  width: 100%;
}

.ex-display-sale {
  .sale-price {
    color: $sales-red;
  }
}

.bronze-bottom {
  border-bottom: 8px solid $bronze;
}
.boxedP {
  max-width: 66.66667%;
  margin:0 auto;
  @media only screen and (max-width: 768px ) {
    max-width: 100%;
  }
}
.new-padding {
  @media only screen and (max-width: 768px ) {
    margin-bottom:20px;
    padding-right: 10px;
    padding-left: 10px;
  }
}
.red-bottom {
  // border-bottom: 5px solid #ff0000;
}
.sale-image {
  width:100%;
  @media screen and (min-width: 768px){
    height:15rem;
  }
  @media screen and (min-width: 1200px){
    height:25vw;
  }
  object-fit: cover;
  object-position: center;
}
.max-height-image {
  max-height: 500px;
  overflow: hidden;
}
.sales, .luxury-sales {
  .card-img-top {
    @media only screen and (min-width: 1130px ) {
      //margin-top:-15%;
    }
    @media only screen and (min-width: 1380px ) {
      //margin-top:-25%;
    }
  }
  .introduction {
    height: 100vh;
  }
  .appliance-pack {
    img {
      max-width: 100%;
      width: 100%;
    }
  }
  .font-bold{
    font-weight: bold
  }

  .heading {
    border-top: none;

    // background-color: rgba(0, 0, 0, .7);

    h1 {
      font-weight: bold;
      font-size: 8rem;
      @media (max-width: #{map-get($grid-breakpoints, md)}) {
        font-size: 4.5rem;
      }
    }
  }

  .red-bottom {
    -webkit-box-shadow: inset 0px -5px 0px 0px rgba(255, 0, 0, 1);
    -moz-box-shadow: inset 0px -5px 0px 0px rgba(255, 0, 0, 1);
    box-shadow: inset 0px -5px 0px 0px rgba(255, 0, 0, 1);
  }

  .articles {
    .sale-price {
      color: $bronze
    }
  }

  .footer-text {
    .h2 {
      font-size: 1.6rem
    }

    p {
      text-transform: none;
    }
  }
}
$red: #C95A3C;
.ex-display-sales {
  .red-span {
    span {
      color: $red
    }
  }

  .title {
    font-size: 1.6rem;

    span {
      color: $red
    }
  }

  p {
    font-size: 1rem;
  }
}

.ex-display-sales .background-taupe.dark-inset {
  background: #000;
  opacity: .6;
  position: relative;
  top: 43%;
  left: 0;
  bottom: 0;
  width: 100%;
}

.home-container {
  margin-top: 5rem;
}

.font-weight-bold {
  font-familyp: 'aller', Helvetica, Arial, sans-serif;
}

.font-weight-medium {
  font-family: 'aller', Helvetica, Arial, sans-serif;

}
.font-weight-regular {
  font-family: 'aller', Helvetica, Arial, sans-serif;

}

.button-on-gray {
  color: #666;
  background-image: none;
  background-color: transparent;
  border-color: #666;
  @media (min-width: #{map-get($grid-breakpoints, lg)}) {

    &:hover {
      color: #fff;
      background-color: #666;
      border-color: #666;
    }
  }
}


.commercial-interior {

  .img-background {
    height: 100%;
  }

  p {
    color: #333;
  }

  .background-taupe {
    background-color: #e8e8dc;
  }

  .heading h1 {
    // font-family: 'oswaldlight'!important;
    // font-weight: bold;
  }

  .quote {
    @media (min-width: #{map-get($grid-breakpoints, sm)}) {
      height: 400px;
    }

    .img-background {
      @media (max-width: #{map-get($grid-breakpoints, sm)}) {
        height: 300px;
      }
    }
  }

  .img-background.full-height {
    min-height: 400px;
  }
}

.middle-container {
  flex-direction: column;
  justify-content: space-around;
  display: flex;
  height: 100%;
}

.commercial-interiors {
  color: #333;

  .introduction {
    background-image: url("/assets/images/interiors-header.jpg"); // default - overriden in templates
  }

  .card {
    background-color: #000000;
    border-color: #000000;
  }


  // .btn-wrapper{
  .btn-outline-primary,
  .button-on-gray {
    color: $blueNew!important;
    background-image: none;
    background-color: transparent;
    border-color: $darkBlueNew;
    font-weight: 500;
    padding: 0.5rem 1.7rem;
    box-shadow: none!important;
    &:focus{

      box-shadow: none!important;
    }
    @media (min-width: #{map-get($grid-breakpoints, lg)}) {

      &:hover {
        background: $darkBlueNew;
        color: #fff;
      }
    }
  }

  .introduction {
    height: 700px;
    height: 100vh;
    position: relative;

    .call-to-action-area {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      padding-top: 25%;
      padding-top: 25vh;

      .fade-in-images {
        display: none;
        align-items: center;
        justify-content: center;
        height: 30%;
        height: 30vh;

        h1 {
          width: 100%;
          font-size: 4rem;
        }

        img {
          width: 80%;
          max-width: 520px;
        }

        h1,
        img {
          display: none;
        }
      }

    
    }
  }

  .home-container {
    // font-family: 'oswaldlight';

    h3 {
      // font-family: 'oswaldextralight';
    }

    button {
      // font-family: 'oswaldlight';
    }
  }

  .commercial-interior-component {
    // font-family: 'oswaldlight';

    .img-background {
      height: 400px;
    }
  }


  .carousel-item {
    &.active {
      display: block;
    }
  }

  .carousel-item-next,
  .carousel-item-prev {
    display: block;
  }

  .carousel-home {
    .card-img-top {
      min-width: auto;
      width: 100%;
    }
  }
}

#commercial-interiors .hide-cities,
#commercial-interior .hide-cities {
  display: none;
}

.padding-container {
  padding: 0 2rem;
}

.btn-smaller {
  padding: 0.25rem 1rem
}
.sales, .luxury-sales {
.heading {
  // border-bottom: 5px solid $red;
  padding-top: 0;
  .btn {
    font-size: 1.2rem;
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: #{map-get($grid-breakpoints, md)}) {
      bottom: 12%;
    }
    border:none;
    &:focus {
      color:white;
      outline:none;
      border:none;
    }
  }
  hr {
    display: none;
  }
  .holder {
    display: inline-block;
    width: 100%;
    // border: 1px solid #FFF;
    #januarysalespannowonspan {
      padding: 2em;
      padding-bottom: 0;
      padding-top: .5em;
    }
    h1 {
      width: 100%;
      padding: 1rem;
      // background: $red;
      position: relative;
      p {
        margin: 0;
      }


    }
  }
}
.sales, .luxury-sales {
  .heading {
    hr {
      display: none;
    }
  }
}


}
@media (max-width: #{map-get($grid-breakpoints, md)}) {
  .sales, .luxury-sales .heading .holder .inner-holder {
    // padding-left: 1rem;
    // width: 90%;
    // padding-right: 1rem;
  }
  .card-img-top {
    min-width: auto;
    width: 100%;
    //max-height: 300px;
    img {
      //max-height: 300px;
    }
  }
}
@media (max-width: #{map-get($grid-breakpoints, sm)}) {
  .sales, .luxury-sales .heading .holder #januarysalespannowonspan {
    // padding: 1em;

  }
}


.years {
  display: inline-block;
  width: 4rem;
  height: 4rem;
  img {
      width: 100%;
      height: 100%;
  }
  span {
    display: block;
  }
  .small {
  }
}

.vote-here{
  padding:  .5rem 1rem;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    border-top-right-radius: 5px;
    left: 0;
    bottom: 0;
    color: white;
    transition: .3s all;
    z-index: 999;
    &.disabled {
      display: none;
    }
    @media (min-width: #{map-get($grid-breakpoints, lg)}) {

      &:hover {
        background: #000;
        cursor: pointer;
      }
    }
}
.position-relative {
  position: relative;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  .btn {
    // border: 2px solid gray;
    color: black;
    background-color:rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    width: 100%;
    transition: .3s all;
    @media (min-width: #{map-get($grid-breakpoints, lg)}) {

      &:hover {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.45);
      }
    }
  }
}



.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.introduction {
  &.full {
    height: 100vh !important;
  }
}

.holder {
  .iq-logo {
    max-width: 80%;
  }
}

.my-kitchen {
  @media (max-width: #{map-get($grid-breakpoints, md)}) {
    max-width: 200px;
    min-width: auto;
  }
}

.animate-logo {
  opacity: 0;

}

.fade-in {
  opacity: 1;
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

@keyframes fadeIn{
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

.red-span {
    color: $sales-red;
}
.gold-span {
  span {
    color: $gold
  }
}
.l-0 {
  letter-spacing: 0;
}

.t-0{
  top:0!important
}

.appliance-button {
  position: absolute;
  bottom: 5px;
  width: 100%;
  left: 0;
  text-align: center;
  background: rgba(0, 0, 0, .5);
  padding: 1rem;
  border: 0;
  &.blue{
    background: rgba(#0c4160, .5);
   
  }
  @media (min-width: #{map-get($grid-breakpoints, lg)}) {
    // &:hover {
    //   border: 0;
    //   background: rgba(0, 0, 0, .9);
    // }
  }
  @media (max-width: #{map-get($grid-breakpoints, sm)}) {
    font-size: .7rem;;
  }
}

.bold-title {
  h1, h2, h3 {
    letter-spacing: 0;
    font-family: 'dashiell', Helvetica, Arial, sans-serif!important;
    -webkit-font-smoothing: antialiased;

  }
}
.red-color {
  color: $red;;
}
.text-1{
  font-size: 1rem
}
.text-12{
  font-size: 1.2rem
}
.text-15{
  font-size: 1.5rem
}
.text-16{
  font-size: 1.6rem
}
.text-18{
  
  font-size: 1.8rem
}
.text-20{
  font-size: 2rem
}
.text-25{
  font-size: 2.5rem
}
.text-30{
  font-size: 3rem
}
.text-13{
  font-size: 1.3rem
}
.text-2{
  font-family: 'dashiell', Helvetica, Arial, sans-serif!important;
  font-size: 2rem
}
.text-3{
  font-family: 'dashiell';
  -webkit-font-smoothing: antialiased;
  font-size: 2rem!important;
  @media (min-width: #{map-get($grid-breakpoints, md)}) {
    font-size: 3rem!important
  }
  @media (min-width: #{map-get($grid-breakpoints, md)}) {
    font-size: 3rem
  }
}
.text-4{
  font-size: 2rem!important;
  font-family: 'dashiell';
  -webkit-font-smoothing: antialiased;
  @media (min-width: #{map-get($grid-breakpoints, md)}) {
    font-size: 3rem!important
  }
  @media (min-width: #{map-get($grid-breakpoints, md)}) {
    font-size: 4rem!important
  }
}
.text-5{
  font-family: 'dashiell';
  -webkit-font-smoothing: antialiased;
  font-size: 2rem!important;

  @media (min-width: #{map-get($grid-breakpoints, sm)}) {
    font-size: 3rem!important
  }
  @media (min-width: #{map-get($grid-breakpoints, xxl)}) {
    font-size: 5rem!important
  }
}
.text-6{
  font-family: 'dashiell';
  -webkit-font-smoothing: antialiased;
  font-size: 3rem!important;
  @media (min-width: #{map-get($grid-breakpoints, md)}) {
    font-size: 4rem!important
  }
  @media (min-width: #{map-get($grid-breakpoints, lg)}) {
    font-size: 6rem!important
  }
}

.text-subheadline {
  font-size: 8vw;

  @media (min-width: #{map-get($grid-breakpoints, lg)}) {
    font-size: 5vw;
  }
}

.sales-heading h2{
  font-size: 4rem;
}
.sales-heading h1{
  font-family: 'dashiell';
  -webkit-font-smoothing: antialiased;
  font-size: 17vw !important;

  @media (min-width: #{map-get($grid-breakpoints, md)}) {
    font-size: 10vw!important;
  }

  @media (min-width: #{map-get($grid-breakpoints, lg)}) {
    font-size: 10vw!important;
  }
}

.sales-heading b{
  color: $gold;
  font-weight: normal;
}


#myGroup .sales .btn {
  @media (max-width: #{map-get($grid-breakpoints, sm)}) {
    font-size: .6rem;;
  }
}

.sale-field {
  overflow: hidden;
  position: relative;
  z-index: 1;
  @media (max-width: #{map-get($grid-breakpoints, sm)}) {
    //height: 50vw;
  }
}
.notification-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0,0,0,.7);
  padding: 1rem;
  .link {
    border-bottom: 1px solid #FFF;
    @media (min-width: #{map-get($grid-breakpoints, lg)}) {

      &:hover {
        cursor: pointer;
        text-decoration: none;;
      }
    }
  }
}

.design-portal-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
  height: 100vh;

  &__header {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 4rem;
    font-family: 'dashiell', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    letter-spacing: normal;
  }

  &__header-small {
    font-size: 2.4rem;
  }
}

.design-portal-box {
  border-bottom: 5px solid rgb(102, 102, 102);

  &__link {
    text-decoration: underline;
    font-weight: 400;
  }
}
.text-normal{
  text-transform: none;
}

.design-portal-box-image {
  background-image: url("/assets/images/ki-about-0000.jpg");
  background-position: center;
  background-size: cover;

  @media only screen and (max-width: 768px) {
    min-height: 100vw;
  }
}
.design-portal {
  .sub-heading {
    p {
    }
  }
}

.carousel-item.active, 
.carousel-item-next, 
.carousel-item-prev {
  align-items: flex-start;
}

//image upload preview

#preview {
  margin-top: 20px;
   img {
     margin-left: 10px;
   }
}
#design-brief {
  .store-locations {
    display:none;
  }
  footer {
    margin-top:30px;
  }
}
#design-portal, #design-portal-open {

}


.btn-outline-secondary {
  &.btn-outline-primary {
    color: $white;
    background-image: none;
    background-color: $blueNew;
    border-color: $white;
  }
}

//same as secondary but a bit thicker and stronger border
.btn-outline-tertiary {
    color: #666;
    background-image: none;
    background-color: transparent;
    border-color: #fff;
}
.btn-outline-quaternary {
  transition: .4s ease;
    color: #fff;
    background-image: none;
    background-color: rgba(0,0,0, .5);
    border-color: #fff;
    @media (min-width: #{map-get($grid-breakpoints, lg)}) {

      &:hover{
        background-color: #fff;
        color: $black;
      }
    }
} 
.btn-outline-quinary {
  transition: .4s ease;
    color: #fff;
    background-image: none;
    background-color: rgba($gold, .5);
    border-color: #fff;
    @media (min-width: #{map-get($grid-breakpoints, lg)}) {

      &:hover{
        background-color: #fff;
        color: $blueNew;
      }
    }
} 

#design-portal, #design-portal-open {
    
}
.design-portal, #design-portal-open {

}
.gradient-border {
    position: relative;
    &:after {
        content: '';
        height: 5px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-image: linear-gradient(90deg, #C95A3C 0%, #57B547 17%, #F8AC33 33%, #EE872F 50%, #80A09E 67%, #38A4C3 83%, #395564 100%);
    }
}
.red-border {
  position: relative;
  &:after {
      content: '';
      height: 5px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #C95A3C;
  }
}
.gold-border {
  position: relative;
  &:after {
      content: '';
      height: 5px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: $gold;
  }
}
.home-header-line{
  border-bottom: 4px solid white;
  width: 2rem;
  @media screen and (min-width: 768px){
    width: 4rem;
  }
}
.home-ml-custom{
  @media screen and (min-width: 992px){
    margin-left: -20rem
  }
  @media screen and (min-width: 1200px){
    margin-left: -24rem
  }
}
.home-mr-custom{
  @media screen and (min-width: 992px){
    margin-right: -20rem
  }
  @media screen and (min-width: 1200px){
    margin-right: -24rem
  }
}
.lh-2{
  line-height: 2!important;
}
.lh-15{
  line-height: 1.5!important;
}
.lh-1{
  line-height: 1!important;
}
.lh-09{
  line-height: 0.9!important;
}
.lh-08{
  line-height: 0.8!important;
}
.lh-07{
  line-height: 0.7!important;
}

.bg-header-title {
    position: relative;
    color: white;
    //font-weight: 600;
    //background-color: rgba(237,28,36, .3);
    span {
      color:#C95A3C;
    }
    &:after {
        //content: '';
        height: 5px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-image: linear-gradient(90deg, #C95A3C 0%, #57B547 17%, #F8AC33 33%, #EE872F 50%, #80A09E 67%, #38A4C3 83%, #395564 100%);
    }
}
.intro-text h2{
  font-weight:bold;
  font-family: 'dashiell';
  font-size: 3rem

}
.intro-text strong{
  color: $gold;
}
.intro-text{
  font-size: 2rem
}
.opacity-0{
  opacity: 0;
}
.opacity-100{
  opacity: 1;
}

.top-headline{
  font-size: 3rem;
  font-weight: normal;
  color: rgba(255,255,255,.5)
  
}
@media screen and (min-width: 768px){
  .top-headline{
    font-size: 6rem;
  }
}
@media screen and (min-width: 1200px){
  .top-headline{
    font-size: 8rem;
  }
}

.bottom-headline{
  font-size: 2.5rem;
  font-weight: normal;
}
@media screen and (min-width: 768px){
  .bottom-headline{
    font-size: 3rem;
  }
}
@media screen and (min-width: 1200px){
  .bottom-headline{
    font-size: 4rem;
  }
}

.headline-container{
  margin-bottom: 20vh;
}

.appliances-text{
  background: rgb(231, 231, 231);
  color: $blueNew;
  h2{
    font-size: 2.5rem;
  }
}
.appliances-text-blue{
  background: $blueNew;
  color:white;
  h2{
    font-size: 2.5rem;
  }
}

.stylish-appliances{

}
.hr-black {
  display: inline-block;
  width: $spacer * 1.5;
  margin-top: $spacer / 2;
  margin-bottom: $spacer / 2;
  border-color: black;
      }

.pb-custom{}

.featured-image-two{
  background-size: cover;
  background-position: center;
}


.fade-in-t{
  opacity: 1;
  animation: 3s fade-in-text-animation;
}
@keyframes fade-in-text-animation {
  from {opacity: 0;}
  to {opacity: 1;}
  }
  .link {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 1px;
      transform: scaleX(0);
      background-color: currentColor;
      transition: 0.4s;
      transform-origin: left;
    }
    @media (min-width: #{map-get($grid-breakpoints, lg)}) {

      &:hover {
        text-decoration: none;
        &:after {
          transform: scaleX(1);
        }
      }
    }
      &.reversed {
      &:after {
        transform: scaleX(1);
      }
      @media (min-width: #{map-get($grid-breakpoints, lg)}) {
        &:hover {
          &::after {
            transform: scaleX(0);
          }
        }
      }
    }
  }
  .headline-animation1{
    transition-delay:.1s; 

  }
  .headline-animation2{
    transition-delay:.2s; 

  }
  .headline-animation3{
    transition-delay:.3s; 
  }
  .animated{
    opacity:1!important; 
    transform: translateY(0px)!important;
  } 
    
  .transition{
    transition-duration: .8s;
    opacity: 0;
    transform:translateY(30px);
    display: inline-block;
  }

 